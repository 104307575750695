import React, { useEffect, useState } from 'react'
import styles from './projectDetail.module.css'
import Info from '../images/info.svg'

const ProjectDetail = (props) => {
    const {genome, currentProject, currentTrait, currentDomain, currentTechnology, handleClear, handleDomain, handleTechnology, handleMouse} = props
    const formatter = new Intl.NumberFormat("en-US", {
        style: 'percent',
        maximumFractionDigits: 0
    })
    let list
    const [scroll, setScroll] = useState(null);
    const [enabled, setEnabled] = useState(true);

     useEffect(() => {
        if(enabled) {
            const gene = genome.find(gene => currentTrait && gene.trait === currentTrait)
            if(gene && gene.ref) {
                if(scroll === null) {
                    setScroll(list.scrollTop)
                }
                if(gene.ref.offsetTop < list.scrollTop) {
                    gene.ref.scrollIntoView({block: 'start'})
                } else if (gene.ref.offsetTop + gene.ref.clientHeight > list.clientHeight + list.scrollTop) {
                    gene.ref.scrollIntoView({block: 'end'})
                }
            } else {
                list.scrollTop = scroll
                setScroll(null)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps            
    }, [currentTrait])

    return (
        <div ref={ref => list = ref} className={styles.projectDetail} role='button' tabIndex={0} onMouseEnter={() => {setEnabled(false)}} onFocus={() => {}} onMouseLeave={() => {setEnabled(true); setScroll(list.scrollTop)}} onBlur={() => {}}>
            <div className={styles.name}>{currentProject.name}</div>
            <div className={styles.section}>
                <div className={styles.label}>Overall score</div>
                <div className={styles.score}>{currentProject.score > 0? (currentProject.score * 10).toFixed(1) : '-'}</div>
            </div>
            {currentProject.domains && (
                <div className={styles.section}>
                    <div className={styles.label}>Domains</div>
                    <div className={styles.tags}>{currentProject.domains.map((domain, i) => <div key={i} className={`${styles.tag} ${domain === currentDomain? styles.active : ''}`} onClick={() => domain === currentDomain? handleClear() : handleDomain({value: domain})} role='button' tabIndex={0} onKeyDown={() => {}}>{domain}</div>)}</div>
                </div>
            )}
            {currentProject.technology?.length > 0 && (
                <div className={styles.section}>
                    <div className={styles.label}>Technology</div>
                    <div className={styles.tags}>{currentProject.technology.map((technology, i) => <div key={i} className={`${styles.tag} ${technology === currentTechnology? styles.active : ''}`} onClick={() => technology === currentTechnology? handleClear() : handleTechnology({value: technology})} role='button' tabIndex={0} onKeyDown={() => {}}>{technology}</div>)}</div>
                </div>
            )}        
            <div role='button' tabIndex={0} onMouseLeave={() => { handleMouse()}} onBlur={() => {}}> 
                {
                    genome.map((gene, i) => {
                        const property = currentProject.properties[gene.trait]
                        if(property) {
                            return (
                                <div key={i} ref={ref => gene.ref = ref} className={`${styles.section} ${styles[gene.group.toLowerCase()]} ${currentTrait? styles.dim : ''} ${currentTrait === gene.trait? styles.highlight : ''}`} onMouseOver={() => { handleMouse(gene.trait)}} onFocus={() => {}} role='button' tabIndex={0} >
                                    <div className={styles.label}>{gene.trait}</div>
                                    <div className={styles.values}>
                                        {
                                            property.values.map((value, i) => (
                                                <div key={i} className={styles.value}>
                                                    <span>{value.name}</span>
                                                    {value.description && <Info className={`${styles.info} tooltip`} data-tooltip={value.description}/>}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={styles.average}>{formatter.format(property.average)}</div>
                                </div>
                            )
                        }
                        return null
                    })
                }
            </div>
            <div className={styles.padding}></div>
        </div>
    )
}

export default ProjectDetail