import React, {useState} from 'react'
import Radar from '../components/radar'
import ProjectList from '../components/projectList'
import ProjectDetail from '../components/projectDetail'
import { useQueryParams, useQueryParam, StringParam } from 'use-query-params'
import { useGoogleAuth } from './googleAuthProvider'
import styles from "./projectGenome.module.css"
import Logo from '../images/m-white.svg'
import SignoutIcon from '../images/signout.svg'
import Tooltip from './tooltip'

const ProjectGenome = (props) => {
    const {googleUser, signOut} = useGoogleAuth()
    const {projects, genome, ideal} = props
    const domains = [...new Set(projects.map(project => project.domains).flat().filter(domain => domain !== null))].sort()
    const technologies = [...new Set(projects.map(project => project.technology).flat().filter(technology => technology !== null))].sort()

    const [, setQuery] = useQueryParams({
        project: StringParam,
        domain: StringParam,
        technology: StringParam
    })

    const [projectParam, setProjectParam] = useQueryParam('project', StringParam)
    const [domainParam, setDomainParam] = useQueryParam('domain', StringParam)
    const [technologyParam, setTechnologyParam] = useQueryParam('technology', StringParam)
    const [trait, setTrait] = useState()

    let project = projects.find(project => project.name === projectParam)
    if(project === undefined) {
        project = projects[0]
        try {
            setProjectParam(project.name, 'replaceIn')
        } catch(e) {
            console.log(e)
        }
    }
    
    const domain = domains.find(domain => domain === domainParam)
    if(domainParam && domain === undefined) {
        try {
            setDomainParam(undefined, 'replaceIn')
        } catch(e) {
            console.log(e)
        }
    }

    const technology = technologies.find(technology => technology === technologyParam)
    if(technologyParam && technology === undefined) {
        try {
            setTechnologyParam(undefined, 'replaceIn')
        } catch(e) {
            console.log(e)
        }
    }

    const handleMouse = (datum) => {
        setTrait(datum)
    }

    const handleProject = (project) => {
        setQuery({
            project: project.name
        })
    }

    const handleDomain = (e) => {
        setQuery({
            technology: undefined,
            domain: e.value
        })
    }

    const handleTechnology = (e) => {
        setQuery({
            technology: e.value,
            domain: undefined
        })
    }

    const handleClear = (e) => {
        setQuery({
            technology: undefined,
            domain: undefined
        })
    }

    const userAvatar = googleUser && googleUser.profileObj? googleUser.profileObj.imageUrl : ''
    const userName = googleUser && googleUser.profileObj? googleUser.profileObj.name : ''

    return  googleUser? (
        <div className={styles.projectGenome}>
            <div className={styles.header}>
                <div className={styles.card}>
                    <Logo width="36px" height="36px"/>
                    <span>Project Genome</span>
                </div>
                <div className={styles.user}>
                    <div className={styles.avatar} style={{backgroundImage: `url(${userAvatar})`}}/>
                    <div className={styles.name}>{userName}</div>
                    <div className={styles.signout} onClick={signOut} role='button' tabIndex={0} onKeyDown={() => {}}>
                        <SignoutIcon/>
                    </div>
                </div>
            </div>
            <ProjectList projects={projects} domains={domains} technologies={technologies} currentProject={project} currentDomain={domain} currentTechnology={technology} handleSelect={handleProject} handleClear={handleClear} handleDomain={handleDomain} handleTechnology={handleTechnology} className={styles.projectList}/>
            <Radar genome={genome} currentProject={project} ideal={ideal} currentTrait={trait} handleMouse={handleMouse} className={styles.radar}/>
            <ProjectDetail genome={genome} currentProject={project} currentTrait={trait} currentDomain={domain} currentTechnology={technology} handleClear={handleClear} handleDomain={handleDomain} handleTechnology={handleTechnology} handleMouse={handleMouse} className={styles.projectDetail}/>
            <Tooltip/>
        </div>
    ) : null
}

export default ProjectGenome