import React, {memo, useState, useEffect} from 'react'
import {Menu, MenuItem, MenuButton, SubMenu} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import styles from './projectList.module.css'
import SearchIcon from '../images/search.svg'
import CloseIcon from '../images/close.svg'
import AlphaIcon from '../images/sort-alpha.svg'
import ScoreIcon from '../images/sort-score.svg'
import FilterIcon from '../images/filter.svg'

const ProjectList = memo((props) => {
    const {projects, domains, technologies, currentProject, currentDomain, currentTechnology, handleSelect, handleClear, handleDomain, handleTechnology, className} = props
    const [sort, sortBy] = useState('name')
    const [search, setSearch] = useState('')
    let list, item

    const filteredProjects = projects
        .filter(project => project.name.toLowerCase().includes(search.toLowerCase()))
        .filter(project => currentDomain === undefined || (project.domains || []).includes(currentDomain))
        .filter(project => currentTechnology === undefined || (project.technology || []).includes(currentTechnology))

    switch(sort) {
        case 'score':
                filteredProjects.sort((a, b) => b.score - a.score)
                break
        case 'name':
        default:
            filteredProjects.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                return 0
            })
            break
    }

    const changeHandler = (e) => {
        setSearch(e.target.value)
    }

    const searchHandler = (e) => {
        if(e.key === 'Escape') {
            setSearch('')
        }
    }

    const keyHandler = (e) => {
        let increment = 0
        switch(e.key) {
            case 'ArrowDown':
                increment = 1
                break
            case 'ArrowUp':
                increment = -1
                break
            case 'Escape':
                setSearch('')
                break
            default:
        }
        if(increment) {
            e.preventDefault()
            let index = filteredProjects.indexOf(currentProject) + increment
            if(index < 0) {
                index = filteredProjects.length - 1
            }
            if(index >= filteredProjects.length) {
                index = 0
            }
            handleSelect(filteredProjects[index])
        }
    }

    const toggleSort = () => {
        switch(sort) {
            case 'score':
                sortBy('name')
                break
            case 'name':
            default:
                sortBy('score')
                break
        }
    }

    const clearHandler = () => {
        setSearch('')
    }

    useEffect(() => {
        if(currentProject && currentProject.ref) {
            if(currentProject.ref.offsetTop < list.scrollTop) {
                currentProject.ref.scrollIntoView({block: 'start'})
            } else if (currentProject.ref.offsetTop + currentProject.ref.clientHeight > list.clientHeight + list.scrollTop) {
                currentProject.ref.scrollIntoView({block: 'end'})
            }
        }
    }, [currentProject, list])

    useEffect(() => {
        document.addEventListener('keydown', keyHandler)
        return () => {
            document.removeEventListener('keydown', keyHandler)
        }
    })

    return (
        <div className={`${className} ${styles.projectList}`}>
            <div className={styles.header}>
                <div className={styles.search}>
                    <SearchIcon className={styles.searchIcon}></SearchIcon>
                    <input type='text' placeholder='Search' onChange={changeHandler} onKeyDown={searchHandler} value={search}></input>
                    <CloseIcon className={styles.clearIcon} style={{visibility: search.length > 0? 'visible' : 'hidden'}} onClick={clearHandler}/>
                </div>
                <div className={styles.sortButton} onClick={toggleSort} role='button' tabIndex={0} onKeyDown={() => {}}>
                    {sort === 'score'? <ScoreIcon/> : <AlphaIcon/>}
                </div>
                <Menu menuButton={<MenuButton className={`${styles.filterButton} ${currentTechnology || currentDomain? styles.active : ''}`}><FilterIcon/></MenuButton>} align='start' offsetX={12} offsetY={0} arrow={false} position='anchor' viewScroll='close' key='right' direction='right'>
                    <MenuItem onClick={handleClear}>Clear filter</MenuItem>
                    <SubMenu label='Domains'>
                        {
                            domains.map((domain, i) => <MenuItem key={i} value={domain} onClick={handleDomain}>{domain}</MenuItem>)
                        }
                    </SubMenu>
                    <SubMenu label='Technology'>
                        {
                            technologies.map((technology, i) => <MenuItem key={i} value={technology} onClick={handleTechnology}>{technology}</MenuItem>)
                        }
                    </SubMenu>
                </Menu>
                
            </div>
            <div ref={ref => list = ref} className={styles.list}>
                {filteredProjects.length?
                    filteredProjects.map((project, i) => (
                        <div key={i} ref={ref => item = project === currentProject? ref : item } className={`${styles.project} ${project === currentProject? styles.currentProject : ''}`} role='button' tabIndex={0} onKeyDown={() => {}} onClick={e => handleSelect(project)}>
                            <span className={styles.name}>{project.name}</span>
                            <span className={styles.score}>{project.score > 0? (project.score * 10).toFixed(1) : '-'}</span>
                        </div>
                    )) : <div className={styles.empty}>No matching projects for '{search}'</div>
                }
            </div>
        </div>
    )
}, (prevProps, nextProps) => prevProps.currentProject === nextProps.currentProject && prevProps.currentDomain === nextProps.currentDomain && prevProps.currentTechnology === nextProps.currentTechnology)

export default ProjectList