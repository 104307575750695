import React from 'react'
import SEO from '../components/seo'
import ProjectGenome from '../components/projectGenome'
import { graphql } from 'gatsby'
import { useGoogleAuth } from '../components/googleAuthProvider'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import './global.css'
import SignIn from '../components/signIn'

const IndexPage = ({data}) => {

  const googleAuth = useGoogleAuth()
  const traits = {}
  const ideal = {}
  data.genome.nodes.forEach(gene => {
    const trait = gene.data.Trait
    ideal[trait] = 0
    traits[trait] = {
      group: gene.data.Trait_group,
    }
  })
  
  const genome = []
  for (const [key, trait] of Object.entries(traits)) {
    genome.push({
      trait: key,
      group: trait.group,
    })
  }
  genome.sort((a, b) => a.group.localeCompare(b.group) || a.trait.localeCompare(b.trait))

  const projects = data.projects.nodes.map(project => {
    const {Internal_name, Technology_stack, Domains, ...entries} = project.data
    const properties = {}
    const values = []
    for (const [key] of Object.entries(entries)) {
      const entry = entries[key]
      if(entry) {
        const property = entry[0].data.Trait
        properties[property] = {}
        properties[property].values = entry.map(d => (
          {
            name: d.data.Name,
            description: d.data.Description
          }
        ))
        properties[property].average = entry.reduce((accumulator, d) => accumulator + d.data.Value, 0) / entry.length
        values.push(properties[property].average)
      } else {
        values.push(null)
      }
    }
    const score = values.some(value => value === null)? 0 : values.reduce((accumulator, value) => accumulator + value, 0) / values.length
    const technology = Technology_stack ? Technology_stack.map(d => d.data.Name) : []

    return {
      score,
      name: Internal_name,
      technology,
      domains: Domains,
      properties
    }
  }).sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0
  })

  projects.reduce((accumulator, project) => {
    for(const [key, value] of Object.entries(ideal)) {
      ideal[key] = Math.max(value, (project.properties[key] || {}).average || 0)
    }
    return ideal
  }, ideal)

  const max = Object.entries(ideal).reduce((accumulator, [key, value]) => accumulator + value || 0, 0) / Object.entries(ideal).length
  
  projects.forEach(project => {
    project.score /= max
  })

  return !googleAuth.isInitialized? (
    null
  ) : !googleAuth.isSignedIn? (
    <SignIn/>
  ) : (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <SEO title='Genome project' description='Explore the traits of different projects we have done or are doing and see how they compare to our current ideals.'/>
        <ProjectGenome projects={projects} genome={genome} ideal={ideal}></ProjectGenome>
      </QueryParamProvider>
    </Router>
  )
}

export const query = graphql`
query {
  projects: allAirtableProjects {
    nodes {
      data {
        Domains
        Technology_stack {
          data {
            Name
          }
        }
        Purpose {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Use_case_proximity {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Users_proximity {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Product_owner {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Team_size {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Contract_type {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Lifecycle {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Technicalities {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Platform {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Duration {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Client_size {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Abstraction_level {
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Layer{
          data {
            Trait
            Name
            Value
            Description
          }
        }
        Internal_name
      }
    }
  },
  genome: allAirtableGenome {
    nodes {
      data {
        Trait
        Trait_group
      }
    }
  }
}`

export default IndexPage