import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3'
import styles from "./radar.module.css"

const Radar = (props) => {
    const {genome, currentProject, ideal, currentTrait, handleMouse, className} = props
    const radius = 300
    const margin = 120
    const stroke = 6
    const angle = Math.PI * 2 / genome.length
    const levels = 5
    const wrap = 120
    const svg = useRef()
    // const curve = d3.curveBasisClosed
    const curve = d3.curveLinearClosed
    const scale = d3.scaleLinear()
        .range([0, radius])
        .domain([0, 1]);

    useEffect(() => {

        const parentNode = svg.current.parentNode

        const pie = d3.pie()
            .sort(null)
            .value(() => 1 / genome.length)

        const arc = d3.arc()
            .innerRadius(radius + stroke / 2)
            .outerRadius(radius + stroke / 2)
            .startAngle(d => d.startAngle - angle / 2)
            .endAngle(d => d.endAngle - angle / 2)

        const resizeObserver = new ResizeObserver(([entry]) => {
            const bounds = svg.current.getBoundingClientRect()
            const reverseScale = (radius + margin) * 2 / Math.min(bounds.width, bounds.height)
            
            d3.select(svg.current)
                .selectAll(`.${styles.axis} foreignObject`)
                .attr('width', wrap / reverseScale)
                .attr('x', (d, i) => (radius + margin / 2) / reverseScale * Math.cos(angle * i - Math.PI / 2) - wrap / 2 / reverseScale)
                .attr('y', (d, i) => (radius + margin / 2) / reverseScale * Math.sin(angle * i - Math.PI / 2))
                .attr('transform', `scale(${reverseScale} ${reverseScale})`)
            
            d3.select(svg.current)
                .selectAll(`.${styles.label}`)
                .attr('width', wrap / reverseScale)
                .attr('x', d => -wrap / 2 / reverseScale)
                .attr('y', d => (-radius * d  / levels + 6) / reverseScale)
                .attr('transform', `scale(${reverseScale} ${reverseScale})`)

            d3.select(svg.current)
                .selectAll(`.${styles.percent}`)
                .style('font-size', 14 * reverseScale)
            
        })  

        const line = d3.radialLine()
            .radius((d) => scale(ideal[d.trait] || 0))
            .angle((d, i) => i * angle)
            .curve(curve)
                
        d3.select(svg.current)
            .attr('class', styles.radar)

        const container = d3.select(svg.current)
            .append('g')
			.attr('transform', `translate(${radius + margin},${radius + margin})`)
            .on('mouseleave', () => {handleMouse()})
        
        const grid = container.append('g')

        // Levels
        grid.selectAll(`.${styles.level}`)
            .data(d3.range(1, levels + 1))
            .enter()
            .append('circle')
            .attr('class', styles.level)
            .attr('r', d => radius / levels * d)

        //Percentages
        grid.selectAll(`.${styles.label}`)
            .data(d3.range(1, levels + 1))
            .enter()
            .append('foreignObject')
            .attr('class', styles.label)
            .attr('width', wrap)
            .attr('height', wrap)
            .attr('x', -wrap / 2)
            .attr('y', d => -radius * d  / levels + 6)
            .append('xhtml:div')
            .text(d => `${100 / levels * d}%`)
        
        //Categories
        container.append('g')
            .selectAll('path')
            .data(pie(genome))
            .enter()
            .append('path')
            .attr('class', d => styles[d.data.group.toLowerCase()])
            .attr('stroke-width', stroke)
            .attr('d', arc)

        //Project acceptance
        container.append('g')
            .append('path')
            .attr('class', styles.area)
        
        //Ideal acceptance
        container.append('g')
            .append('path')
            .attr('class', styles.ideal)
            .datum(genome)
            .attr('d', line)

        //Axes
        container.append('g')
            .selectAll(`.${styles.axis}`)
            .data(pie(genome))
            .enter()
            .append('g')
            .attr('class', d => `${styles.axis} ${d.data.trait === currentTrait? styles.highlight : ''}`)
            .on('mouseover', (e, datum) => {handleMouse(datum.data.trait)})
            .each(function(d, i) {

                d3.select(this)
                    .append('path')
                    .attr('class', styles.hit)
               		.attr('d', arc.innerRadius(0).outerRadius(radius + margin))
                
                d3.select(this)
                    .append('line')
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', () => radius * Math.cos(angle * i - Math.PI / 2))
			        .attr('y2', () => radius * Math.sin(angle * i - Math.PI / 2))
                
                d3.select(this)
            		.append('circle')
                    .attr('r', 1)
                
                d3.select(this)
            		.append('text')
                    .attr('class', styles.percent)
                    .style('font-size', 14)
                
                d3.select(this)
                    .append('foreignObject')
                    .attr('width', wrap)
                    .attr('height', wrap)
                    .attr('x', () => (radius + margin / 2) * Math.cos(angle * i - Math.PI / 2) - wrap / 2)
                    .attr('y', () => (radius + margin / 2) * Math.sin(angle * i - Math.PI / 2))
                    .append('xhtml:div')
                    .attr('class', styles.trait)
                    .text(d => {
                        return d.data.trait
                    })

            })                
        
        resizeObserver.observe(parentNode)

        return () => {
            resizeObserver.unobserve(parentNode)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps            
    }, [])

    useEffect(() => {
        const line = d3.radialLine()
            .radius((d) => scale((currentProject.properties[d.trait] || {}).average || 0))
            .angle((d,i) => i * angle)
            .curve(curve)

        d3.select(svg.current)
            .select(`.${styles.area}`)
            .datum(genome)
            .transition()
            .attr('d', line)

        d3.select(svg.current)
            .selectAll(`.${styles.axis}`)
            .each(function(d, i) {

                const value = (currentProject.properties[d.data.trait] || {}).average || 0

                d3.select(this)
                    .select('circle')
                    .transition()
                    .attr('cx', () => scale(value) * Math.cos(angle * i - Math.PI / 2))
                    .attr('cy', () => scale(value) * Math.sin(angle * i - Math.PI / 2))
                
                d3.select(this)
                    .select(`.${styles.percent}`)
                    .attr('x', () => scale(value) * Math.cos(angle * i - Math.PI / 2))
                    .attr('y', () => scale(value) * Math.sin(angle * i - Math.PI / 2))
                    .attr('dy', -12)
                    .text(`${(value * 100).toFixed(0)}%`)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject])

    useEffect(() => {
        d3.select(svg.current)
            .classed(styles.dim, currentTrait !== undefined)
        
        d3.select(svg.current)
            .selectAll(`.${styles.axis}`)
            .classed(styles.highlight, d => d.data.trait === currentTrait)
    }, [currentTrait])

    return (
        <div className={`${className} ${styles.container}`}>
            <div className={styles.title}>Percentage of people at Manas that enjoy projects with these traits</div>
            <svg width='100%' viewBox={`0 0 ${(radius + margin) * 2} ${(radius + margin) * 2}`} preserveAspectRatio='xMidYMid meet' ref={svg}/>
            <div className={styles.legends}>
                    <div className={styles.legend}>
                        <div className={styles.externalitiesRef}></div>
                        <div className={styles.label}>Externalities</div>
                    </div>
                    <div className={styles.legend}>
                        <div className={styles.methodologyRef}></div>
                        <div className={styles.label}>Methodology</div>
                    </div>
                    <div className={styles.legend}>
                        <div className={styles.technologyRef}></div>
                        <div className={styles.label}>Technology</div>
                    </div>
                    <div className={styles.legend}>
                        <div className={styles.projectAcceptanceRef}></div>
                        <div className={styles.label}>Project acceptance</div>
                    </div>
                    <div className={styles.legend}>
                        <div className={styles.idealAcceptanceRef}></div>
                        <div className={styles.label}>Ideal acceptance</div>
                    </div>
                </div>
        </div>
    )
}

export default Radar