import React from 'react'
import styles from './signIn.module.css'
import Logo from '../images/manas.tech-black.svg'
import Radar from '../images/radar.svg'
import { useGoogleAuth } from './googleAuthProvider'

const SignIn = () => {
    const { signIn } = useGoogleAuth()

    return (
        <div className={styles.signIn}>
            <Logo className={styles.logo}/>
            <Radar className={styles.radar}/>
            <span>Project Genome</span>
            <div className={styles.button} onClick={() => signIn()} role='button' tabIndex={0} onKeyDown={() => {}}>
                Sign in
            </div>
        </div>
    )
}

export default SignIn